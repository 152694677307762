<template>
  <PageLayout>
    <template #header>
      <k-title>{{ $t('candidate.tabs.company') }}</k-title>
    </template>

    <template
      v-if="candidate.isZzp"
      #default
    >
      <k-display-group language-prefix="candidate.fields">
        <v-row>
          <v-col>
            <k-dossier>
              <v-row no-gutters>
                <v-col cols="6">
                  <k-text-display field="selfEmployedSetting.companyName">
                    {{
                      candidate.selfEmployedSetting.companyName
                    }}
                  </k-text-display>
                  <k-text-display
                    v-if="candidate.selfEmployedSetting.vatNumber"
                    field="selfEmployedSetting.vatNumber"
                  >
                    {{
                      candidate.selfEmployedSetting.vatNumber
                    }}
                  </k-text-display>
                  <k-text-display field="selfEmployedSetting.chamberOfCommerceNumber">
                    {{
                      candidate.selfEmployedSetting.chamberOfCommerceNumber
                    }}
                  </k-text-display>
                  <k-text-display field="rate">
                    € {{ candidate.rate }}
                  </k-text-display>
                </v-col>
                <v-col cols="6">
                  <k-text-display field="selfEmployedSetting.companyAddress">
                    <AddressDisplay :address="candidate.selfEmployedSetting.address" />
                  </k-text-display>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6">
                  <k-text-display field="selfEmployedSetting.iban">
                    {{
                      candidate.selfEmployedSetting.iban
                    }}
                  </k-text-display>
                </v-col>
                <v-col cols="6">
                  <k-text-display field="selfEmployedSetting.ibanAscription">
                    {{ candidate.selfEmployedSetting.ibanAscription }}
                  </k-text-display>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <k-text-display
                    v-if="candidate.selfEmployedSetting.invoiceBillingIntervalId"
                    field="selfEmployedSetting.billingIntervalOptions"
                  >
                    {{ invoiceBillingIntervalText }}
                  </k-text-display>
                </v-col>
              </v-row>
            </k-dossier>
          </v-col>
        </v-row>
      </k-display-group>
    </template>
  </PageLayout>
</template>

<script>
import PageLayout from '@/components/layout/PageLayout.vue';
import KTitle from '@/components/layout/KTitle.vue';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KDossier from '@/components/layout/KDossier.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import AddressDisplay from '@/modules/address/components/AddressDisplay.vue';
import eventBus from '@/application/eventBus.ts';
import { invoiceBillingIntervalType } from '@/application/enums/invoiceBillingIntervalType.js';

export default {
  name: 'SelfEmployment',
  components: {
    KTextDisplay,
    KDossier,
    KTitle,
    KDisplayGroup,
    AddressDisplay,
    PageLayout,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.candidate.isZzp) {
        next();
      } else {
        vm.$router.push({ name: '404' });
      }
    });
  },
  props: {
    candidate: {
      type: Object,
    },
    getCandidate: {
      type: Function,
    },
  },
  computed: {
    invoiceBillingIntervalText() {
      const intervalKey = Object.keys(invoiceBillingIntervalType)
        .find(key => invoiceBillingIntervalType[key] === this.candidate.selfEmployedSetting.invoiceBillingIntervalId);
      return this.$t(`employerSettings.invoiceBillingIntervalValues.${intervalKey}`);
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'candidate.index' },
        text: this.$tc('candidate.title', 2),
      },
      {
        exact: true,
        to: { name: 'candidate.show' },
        text: this.candidate.name,
      },
      {
        exact: true,
        to: { name: 'candidate.selfEmployment' },
        text: this.$tc('candidate.tabs.company', 2),
      }],
    );
  },
};
</script>
